.App {
  background-color: #ccddff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #646464;
}

.App-header {
  background-color: #ccddff;
  display: inline-block;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: #646464;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-link {
  color: #050809;
}

.App-button {
  border: 1px solid;
  border-radius: 10px;
  background-color: #f0f0f0;
  margin: 10px;
  padding: 10px;
}

.App-button:hover {
  background-color: #808080;
  cursor: pointer;
}

.Login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
